<template>
  <div class="wrapper">
    <template v-if="loaded">
      <va-navibar></va-navibar>
      <va-slider :slideMenuItems="slideMenuItems" title="会员中心"></va-slider>
      <va-content-wrap></va-content-wrap>
      <!--<footer class="main-footer">
          <div class="float-right d-none d-sm-block">
              <b>Version</b> 6.0
          </div>
          <strong>Copyright &copy; 2015-2019 <a href="http://panorover.com">PanoRover.com</a>.</strong> All rights reserved.
      </footer>-->
      <aside class="control-sidebar control-sidebar-dark">
        <div class="control-sidebar-content p-3">
          <nav v-if="setting" class="nav flex-column">
            <a v-if="global.CONSOLE_URL" class="nav-link" :href="global.CONSOLE_URL">新版个人中心</a>
            <a v-if="$inRole('管理员')&&global.CONSOLE_URL" class="nav-link" :href="global.CONSOLE_URL+'/Admin'">新版管理中心</a>
            <a v-if="$inRole('管理员')" class="nav-link" :href="setting.adminUrl">管理中心</a>
            <router-link v-if="$inRole('管理员')||$inRole('公共管理')" :to="{ name: 'PublicHome'}" class="nav-link">公共管理</router-link>
            <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>
            <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
            <router-link v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" :to="{ name: 'GroupIndex'}" class="nav-link">项目管理</router-link>
          </nav>
        </div>
      </aside>
    </template>
    <div v-else><i class="fas fa-spinner fa-pulse"></i> 加载中…</div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'
  import VAContentWrap from '@/components/ContentWrap.vue'
  import store from '@/vuex/store.js'
  //import slideMenuItems from '@/lib/menu.js'

  export default {
    name: 'default',
    data() {
      return {
        signedIn: false,
        slideMenuItems: null,
        loaded: false,
        global: window.global,
      }
    },
    methods: {
      ...mapActions([
        'getNoticesCount',
        'getNotices',
        'getMessagesCount',
        'getMessages',
        'getSetting',
        'getUser'
      ]),
      loadData: function () {
        this.loadMenu();
        var p = [];
        this.getNoticesCount()
        this.getNotices()
        this.getMessagesCount()
        this.getMessages()
        p.push(this.getSetting())
        this.getUser()
        this.startSignalR()
        Promise.all(p).then(() => {
          this.loaded = true
        })
      },
      loadMenu: function () {
        var _this = this;
        this.$axios.get('/Api/My/Menus').then(function (res) {
          _this.slideMenuItems = res.data;
          //$('.sidebar-menu').tree()
        }).catch(err => {
          console.log(err)
          if (err) {
            _this.$message({
              message: '发生了错误 关闭此消息尝试重新加载',
              type: 'error',
              zIndex: 9999,
              showClose: true,
              duration: -1,
              onClose: () => {
                _this.loadData();
              }
            })
          }
        })
      }

    },
    created: function () {
      this.loadData();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
      'va-content-wrap': VAContentWrap,
    },
    store
  }
</script>

<style>
</style>
